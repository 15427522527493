<template>
  <div class="pressCenterDetails">
    <CompanyProfileBanner :index="'3'" :imageUrl="imageUrl"></CompanyProfileBanner>
    <BreadcrumbAndSubtag
      :breadcrumbs="{
        path: '/pressCenter',
        name:  '新闻中心',
        childName: secondaryRoutingIndex === '6-1' ? '公司新闻' : '行业新闻'
      }"
    ></BreadcrumbAndSubtag>
    <div class="detailsContent">
      <div class="contents">
        <div class="article">
          <h4>{{ title }}</h4>
          <div class="subtitle">
            <span>发布时间: {{ time }}</span>
            <span>浏览次数: {{ views }}</span>
          </div>
          <div class="articleContent" v-html="content"></div>
        </div>
        <p v-show="prevTitle.title">
          上一篇：<span class="switchArticles" @click="handleChangeArticlesFun(prevTitle.id)">{{ prevTitle.title }}</span>
        </p>
        <p v-show="nextTitle.title" style="margin-top: 4px">
          下一篇：<span class="switchArticles" @click="handleChangeArticlesFun(nextTitle.id)">{{ nextTitle.title }}</span>
        </p>
      </div>
    </div>
    <footerMain></footerMain>
  </div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'
import Secret from "@/utils/secret";
import {mapGetters} from "vuex";

export default {
  name: "pressCenterDetails",
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain
  },
  data() {
    return {
      imageUrl: '',
      parentParam: JSON.parse(decodeURIComponent(atob(this.$route.query.data))),
      title: '',
      time: '',
      views: '',
      content: '',
      prevTitle: {},
      nextTitle: {},
    }
  },
  computed: {
    ...mapGetters(['secondaryRoutingIndex','articlesId', 'pageRefreshed'])
  },
  created() {
    this.loadResourceOfPageFun()
    this.getArticleContentFun()
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleUnload);
  },
  methods: {
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 15, locationType: 1}).then((res) => {
        if(res.code === 100) {
          this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    getArticleContentFun() {
      if(this.articlesId !== null && this.articlesId !== 'undefined') {
        const _param = {
          articleId: this.articlesId,
          pageId: this.parentParam.pageId
        }
        if(this.pageRefreshed !== 'true') {
          _param.addViewsFlag = 1
        }
        api.getArticleContent(_param).then((res) => {
          if(res.code === 100) {
            console.log(res.data)
            this.title = res.data.title
            this.time = Secret.ConvertToDateFun(res.data.updateTime, 'yyyy-MM-dd')
            this.views = res.data.views
            this.content = JSON.parse(res.data.content).replace(/&nbsp;/g, '')
            if(res.data.lastOne) {
              this.prevTitle = res.data.lastOne
            } else {
              this.prevTitle = {}
            }
            if(res.data.nextOne) {
              this.nextTitle = res.data.nextOne
            }else {
              this.nextTitle = {}
            }
          }else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          if(err.msg) {
            this.$message.error(err.msg);
          }
        });
      } else {
        this.$router.push({path: '/pressCenter'})
      }
    },
    handleChangeArticlesFun(id) {
      this.$store.commit('SET_ARTICLES_ID', id)
      this.getArticleContentFun()
    },
    handleUnload(event) {
      this.$store.commit('SET_PAGE_REFRESHED', 'true')
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleUnload);
  },

}
</script>

<style scoped lang="scss">
.pressCenterDetails {
  .detailsContent {
    margin: auto;
    .contents {
      width: 1200px;
      padding: 2em 0;
      margin: auto;
      p {
        cursor: pointer;
        text-align: left;
        font-size: 14px;
        margin-bottom: 0px;
        .switchArticles {
          cursor: pointer;
        }
      }
      .article {
        padding: 10px 0 30px 0;
        .subtitle {
          font-size: 12px;
          color: #5a5a5a;
          padding-bottom: 20px;
          border-bottom: 1px solid #E5E5E5;
          span {
            margin-right: 20px;
          }
        }
        .articleContent {
          padding-top: 20px;
          text-align: left;
          //::v-deep span {
          //  text-indent: 2em;
          //  display: inline-block;
          //}
          ::v-deep p {
            text-indent: 2em;
          }
          ::v-deep figure {
            width: 100% !important;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
